
import { createGlobalStyle } from 'styled-components'

import { Normalize } from './normalize'
import { Typeface } from './typeface'
import { Global } from './global'

export const Sheet = createGlobalStyle`
    ${Normalize}
    ${Typeface}
    ${Global}
`