
// external

import React from 'react'
import Helmet from 'react-helmet'

import { Sheet } from 'layouts/theme/sheet'

export const DOMWrapper = ({  children }) => {

    return(
        <>

            <Helmet
                title='Swamp Trek'
            />

            <Sheet />

            <main style={{ overflowX: "hidden" }}>{children}</main>

        </>
    );

}