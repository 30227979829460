
import { css } from 'styled-components'

export const Typeface = css`
    @import url('https://fonts.googleapis.com/css?family=Lato|Lora&display=swap');

    body {
        font-family: 'Lato', sans-serif;
        font-size: 1.2em;
        line-height: 1.5;
    }

    h1, h2, h3, h4, h5, h6, sub {
        font-family: 'Lora', serif;
        font-weight: normal;
        line-height: 1;
    }

    h1      { font-size: 3em; }
    h2      { font-size: 1.3em; }
    h3      { font-size: 1.2em; }
    h5, h6  { font-size: 1em; }

`