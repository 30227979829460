
import { css } from 'styled-components'

export const Global = css`

    p, article {
        margin: 0;
    }

    img, svg {
        max-height: 100%;
        max-width:  100%;
        height:     auto;
        width:      auto;
    }

    svg { 
        display: inline-block;
        vertical-align: middle;
    }

    *, *:after, *:before {
        box-sizing: border-box;
    }

`